// @flow

const { REACT_APP_API_URL, REACT_APP_OKTA_CLIENT_ID } = process.env;

if (!REACT_APP_API_URL) {
  throw new Error("REACT_APP_API_URL environment variable must be provided");
}

export const OKTA_CLIENT_ID: string = REACT_APP_OKTA_CLIENT_ID;
export const APP_API_URL: string = REACT_APP_API_URL;
export const HOSTED_DOMAIN = "khealth.com";
export const GOOGLE_LOGIN_SCOPE = "email";
