// @flow

import React from "react";
import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import oktaAuthClient from "./infrastructure/oktaAuthClient";
import { Switch, Route, useHistory } from "react-router-dom";
import { pageComponent } from "microcomponents/Init";
import { oktaSignInConfig } from "infrastructure/oktaConfig";

const SecuredApp = pageComponent(() => import("SecuredApp"));
const Login = pageComponent(() => import("./microcomponents/Login"));

const App = () => {
  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  const customAuthHandler = () => {
    history.push("/login");
  };

  return (
    <Security
      oktaAuth={oktaAuthClient}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Route path="/callback" component={LoginCallback} />
      <Route path="/login">
        <Login config={oktaSignInConfig} />
      </Route>
      <SecureRoute path="/" component={SecuredApp} />
    </Security>
  );
};
export default App;
