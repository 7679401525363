import { OKTA_CLIENT_ID } from "./env";

const SECURITY_REDIRECT = window.location.origin + "/signin/callback";

const oktaAuthConfig = {
  issuer: "https://khealth.okta.com/oauth2/default",
  clientId: "0oacx7b80rEHDEK3O357",
  redirectUri: SECURITY_REDIRECT,
  scopes: ["openid", "profile", "email"]
};

const oktaSignInConfig = {
  baseUrl: "https://khealth.okta.com",
  clientId: "0oacx7b80rEHDEK3O357",
  redirectUri: SECURITY_REDIRECT,
  authParams: {
    // If your app is configured to use the Implicit flow
    // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
    // you will need to uncomment the below line
    // pkce: false
  }
  // Additional documentation on config options can be found at https://github.com/okta/okta-signin-widget#basic-config-options
};

export { oktaAuthConfig, oktaSignInConfig };
