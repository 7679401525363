import React from "react";
import { asyncComponent } from "react-async-component";

const LoadingComponent = props => (
  <div>
    <h5>Loading page...</h5>
  </div>
);

const ErrorComponent = ({ error }) => {
  console.error(error);
  return <div>Couldn't load the app!</div>;
};

export const pageComponent = resolve =>
  asyncComponent({
    resolve,
    LoadingComponent,
    ErrorComponent
  });
