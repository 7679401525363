import React from "react";
import ReactDOM from "react-dom";
import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";
import { Router } from "react-router-dom";
import { unregister } from "infrastructure/registerServiceWorker";
import history from "infrastructure/history";
import "./index.css";
import oktaAuthClient from "infrastructure/oktaAuthClient";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Route, Switch } from "react-router-dom";
import { pageComponent } from "microcomponents/Init";
import App from "App";

const isLocal = (): boolean => {
  const { REACT_APP_API_URL } = process.env;
  return Boolean(REACT_APP_API_URL) && REACT_APP_API_URL.includes("localhost");
};

if (isLocal()) {
  document.title = "Hug Me (Local)";
}

ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  document.getElementById("root")
);

// This has changed in newer create-react-app versions and is unclear
// if makes unpredictable behavior.
// registerServiceWorker();
unregister();
